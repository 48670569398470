import { graphql } from 'gatsby'
import PageBlogPost from '../components/PageBlogPost'

export default PageBlogPost

export const query = graphql`
  query PageBlogPost($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: mdx(fields: { slug: { eq: $slug } }) {
      timeToRead
      body
      frontmatter {
        id
        listing_id
        post_title
        date
        meta {
          description
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    articles: allMdx(
      limit: 9
      filter: { fields: { sourceName: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            id
            post_title
            date
            image {
              childImageSharp {
                gatsbyImageData(width: 960, layout: CONSTRAINED)
              }
            }
            tags
            tags_private
          }
        }
      }
    }
  }
`
