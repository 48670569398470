import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Popover from '../Popover'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'
import * as style from './style.module.scss'

const Author = ({ listing }) => {
  const popoverBadge = usePopoverState()

  return (
    <div className={style.authorContainer}>
      <div className={style.author}>
        {listing?.frontmatter.icon && (
          <GatsbyImage
            image={getImage(listing.frontmatter.icon)}
            alt={listing.frontmatter.post_title}
            className={style.image}
          />
        )}
        <div className={style.content}>
          {listing && (
            <Link className={style.listing} to={`/${listing.frontmatter.id}`}>
              {listing.frontmatter.post_title}
            </Link>
          )}
          {listing?.frontmatter.post_tag && (
            <div className={style.category}>
              {listing.frontmatter.post_tag}
            </div>
          )}
          <div className={style.row}>
            {listing?.frontmatter.badge && (
              <>
                <PopoverDisclosure
                  className={style.badge}
                  {...popoverBadge}>
                  {listing.frontmatter.badge}
                </PopoverDisclosure>

                <Popover {...popoverBadge} aria-label="What is this?" tabIndex={0}>
                  <div className={style.popoverContent}>
                    <p>This contributor is a subject-matter expert, and is paid for their contributions.</p>
                  </div>
                </Popover>
              </>
            )}
            {/* {listing && (
              <div className={style.readers}>
                <strong>{listing.frontmatter.view_count}</strong> monthly readers
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className={style.legalese}>
        <p>
          This site contains user submitted content and opinions and is for
          informational purposes only. Every dog is different and not all
          factors are detailed in these articles. Doggos.com may recommend or
          promote certain articles based on popularity and other metrics, but
          cannot provide guarantees about the efficacy of proposed solutions.
        </p>
      </div>
    </div>

  )
}

export default Author
